// Colors
$dark-gray: #4A4A4A;
$light-gray: #979797;
$apple-green: #AFCA0B;
$divider-color:	#E8E6F4;
$error: #F03E41;

.loading {
	color: $apple-green;
	padding: 20px; }

.hidden {
	visibility: hidden; }

