// Variables
$border-radius-box: 8px;
$border-radius-input: 3px;
$border-radius-button: 50px;


// Colors
$dark-gray: #4A4A4A;
$light-gray: #979797;
$apple-green: #AFCA0B;
$blue-color: #1E87F0;
$dark-blue-color: #4D51FB;
$turquoise-color: #50E3C2;
$orange-color: #F29200;
$yellow-color: #FAD500;
$red-color: #F03E41;
$purple-color: #5D47FC;
$divider-color:	#E7E7E7;
$border-color: #cccccc;
$error: #F03E41;
$menu-item-color: #f7f7f7;


.select-field-wrapper {
	position: relative;
	text-align: left;
	margin: auto;
	padding-bottom: 25px;
	min-height: 100px; }


