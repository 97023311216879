// Variables
$border-radius-box: 25px;
$border-radius-input: 3px;
$border-radius-button: 50px;


// Colors
$dark-gray: #4A4A4A;
$light-gray: #979797;
$apple-green: rgb(175, 202, 11);
$blue-color: #1E87F0;
$dark-blue-color: #4D51FB;
$turquoise-color: #50E3C2;
$orange-color: #FC754B;
$yellow-color: #FAD500;
$brown-color: #AB6E50;
$red-color: #F03E41;
$purple-color: #5D47FC;
$divider-color: #e7e7e7;
$border-color: #cccccc;
$border-light-color: #eaedd6;
$error: #F03E41;
$menu-item-color: #262845;
$selected-color: #647f0b;
$light-green: #c6e120;
$ghost-white: #F9F8FE;
$lavender: #E5E8F6;
$table-hover-background: #F5F8E1;
$calendar-day-color: #6A6A6A;
$blue-gray: #E3E8F9;

$z-index-loading: 400;
$z-index-toast: 300;
$z-index-modal: 200;
$z-index-modal-background: 199;
$z-index-dropdown: 150;
$z-index-header: 100;
$z-index-error: 10;

// Mixins

@mixin extra-small {
	@media only screen and (max-width: 576px) {
		@content; } }

@mixin small {
	@media only screen and (max-width: 768px) {
		@content; } }

@mixin medium {
	@media only screen and (max-width: 992px) {
		@content; } }

@mixin large {
	@media only screen and (max-width: 1200px) {
		@content; } }

@mixin extra-large {
	@media only screen and (min-width: 1200px) {
		@content; } }

// Color classes

.green {
	color: $apple-green !important; }

.blue {
	color: $blue-color !important; }

.turquoise {
	color: $turquoise-color !important; }

.orange {
	color: $orange-color !important; }

.yellow {
	color: $yellow-color !important; }

.red {
	color: $red-color !important; }

.dark-gray {
	color: $dark-gray !important; }

.green-bg {
	background: $apple-green !important; }

.blue-bg {
	background: $blue-color !important; }

.turquoise-bg {
	background: $turquoise-color !important; }

.orange-bg {
	background: $orange-color !important; }

.yellow-bg {
	background: $yellow-color !important; }

.red-bg {
	background: $red-color !important; }

// Default styles

* {
	box-sizing: border-box;
	line-height: 1.4; }

html, body {
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	background: #f6f7fb;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	z-index: 1;
	position: relative;
	overflow: hidden; }

a {
	text-decoration: none;
	cursor: pointer;
	color: #1C1C1C; }

h1, h2, h3, h4, h5 {
	font-family: 'Poppins', sans-serif; }

h1 {
	font-size: 40px;
	font-weight: bold;
	text-align: left;
	line-height: 40px; }

h2 {
	font-size: 16px;
	line-height: 26px; }

h3 {
	font-size: 16px;
	font-weight: normal; }

h4 {
	font-size: 14px;
	font-weight: 700; }

ul {
	list-style-type: none;
	padding: 0;
	margin: 0; }

strong {
	font-weight: 600; }

textarea {
	resize: none; }

table {
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-collapse: collapse;
	border-spacing: 0; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0; }

.container {
	max-width: 100% !important; }

.body {
	position: relative; }

.background {
	height: 100%;
	overflow: auto;
	position: relative;
	background: url("../images/login-bg.jpg") center;
	background-size: cover;

	& > .overlay {

		width: 100%;
		min-height: 100%;
		background: rgba(255, 255, 255, 0.8); } }

.clearfix::after {
	content: ' ';
	display: block;
	clear: both; }

*:focus {
	outline: 0; }

.button:focus {
	outline: 0; }

.container.simple {
	height: 100vh;
	width: 100%; }

.link {
	color: $apple-green;
	text-decoration: underline; }

// Default classes

.text-left {
	text-align: left !important; }

.text-right {
	text-align: right !important; }

.text-center {
	text-align: center !important; }

.pull-left {
	float: left; }

.pull-right {
	float: right; }

.full-width {
	width: 100%; }

.no-margin {
	margin: 0; }

.bold {
	font-weight: bold; }

.dot {
	display: inline-block;
	border-radius: $border-radius-button;
	margin: 0 10px;
	height: 12px;
	width: 12px;
	vertical-align: middle;

	& + span {
		display: inline-block;
		vertical-align: middle; } }

.state {
	display: inline-block;
	border-radius: $border-radius-button;
	height: 15px;
	width: 15px;
	vertical-align: middle;
	background: url("../images/icon-ok.svg") center no-repeat;

	&.invalid {
		background: url("../images/icon-error.svg") center no-repeat; }

	&.unsuitable {
		background: url("../images/icon-warning.svg") center no-repeat; }

	&.invalid.unsuitable {
		background: url("../images/icon-error-multiple.svg") center no-repeat; } }

.subtitle {
	margin-bottom: 40px;
	color: $dark-gray; }

.main-heading {
	margin-bottom: 0;
	margin-top: 15px; }

.long-text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; }

.flex-box {
	display: flex;

	&.vertical {
		flex-direction: column;
		align-items: center; } }

//Switch

.switch-wrapper {
	.switch {
		vertical-align: middle;
		margin-left: 10px; }

	p {
		display: inline-block; } }

// Login form

.login-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.login-form {
		position: relative;
		padding: 50px;
		width: 455px;
		background: #fff;
		border-radius: $border-radius-box;
		text-align: center;
		box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.25);
		@include small {
			max-width: 455px;
			width: 100%; }

		h1 {
			font-size: 19px;
			font-weight: 600;
			line-height: 29px;
			margin: 0 0 30px 0; }

		.checkbox-wrapper {
			margin: 0;

			.checkbox {
				font-size: 10px;
				color: $light-gray;
				padding-top: 2px; } } } }

.forgot-password {
	color: #B6B6B6;
	font-size: 12px;

	span {
		font-size: 10px;
		font-weight: 500;
		line-height: 16px;
		text-decoration: underline;
		margin-bottom: 15px;
		padding-top: 2px;
		padding-left: 10px; } }

// form error

%error-bubble {
	position: relative;

	border: 1px solid #ccc;
	padding: 10px;
	color: #333; }

.tooltip {
	white-space: nowrap;
	position: absolute;
	top: 25px;
	left: -35px;
	background: $error;
	box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.25);
	padding: 12px 20px;
	font-size: 12px;
	color: white;
	border-radius: $border-radius-button;
	z-index: $z-index-dropdown; }

.tooltip:after, .arrow_box:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none; }

.tooltip:after {
	border: 10px rgba(136, 183, 213, 0);
	border-bottom-color: $error;
	margin-left: -10px; }

.tooltip:before {
	border: 10px rgba(194, 225, 245, 0);
	border-bottom-color: $error;
	margin-left: -10px; }


// form elements

.stats-period-picker-wrapper {
	position: relative;
	float: right;
	width: 300px;
	margin-bottom: 20px;
	padding: 15px;

	.selected-period {
		cursor: pointer;
		text-align: center;
		border: none;
		font-size: 16px;
		font-weight: bold;

		&::after {
			content: ' ';
			display: block;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -9px;
			height: 20px;
			width: 20px;
			background: url("../images/ic-chevron-down.svg") center no-repeat;
			clear: both;
			filter: grayscale(100%) brightness(0%); } }

	.stats-period-picker {
		position: absolute;
		display: none;
		background-color: white;
		border: 1px solid $border-light-color;
		border-radius: $border-radius-box;
		z-index: $z-index-dropdown;
		left: 50%;
		transform: translateX(-50%);
		top: 130%;
		width: 250px;
		box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15);

		&.active {
			display: block; }

		.stats-period {
			padding: 15px;
			background: none;
			border: none;
			width: 50%;
			display: inline-block;
			text-align: center;
			font-weight: bold;
			font-size: 13px;
			text-transform: uppercase;
			cursor: pointer;
			color: $light-gray;

			&:hover {
				color: $apple-green; } } } }


.stats-range-picker {
	float: right;
	width: 280px;

	.input-wrapper {
		border: none;
		position: relative;
		cursor: pointer;

		&::after {
			content: ' ';
			display: block;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 20px;
			height: 20px;
			width: 20px;
			background: url("../images/ic-chevron-down.svg") center no-repeat;
			clear: both;
			filter: grayscale(100%) brightness(0%); }

		&.empty::after {
			opacity: 0.5; }

		.range-picker-input {
			cursor: pointer;
			text-align: center;
			border: none;
			font-size: 16px;
			font-weight: bold; } } }


.date-picker {
	background-color: white;
	width: 302px;
	display: none;
	position: absolute;
	z-index: $z-index-dropdown;
	border-radius: $border-radius-box;
	border: 1px solid $border-color;

	&.filter {
		position: relative; }

	&.range-picker {
		right: 0;

		.range-tips {
			margin-bottom: 5px;

			.period-button {
				background: none;
				border: none;
				width: 50%;
				display: inline-block;
				text-align: center;
				font-weight: bold;
				font-size: 13px;
				text-transform: uppercase;
				padding: 7px 0;
				cursor: pointer;
				color: $light-gray; } }

		.range-hint {
			text-align: center;
			font-weight: bold;
			font-size: 14px;
			color: $light-gray;
			margin-top: 40px; } }

	&.active {
		display: block; }

	.date-picker-calendar {
		padding: 20px;

		table {
			width: 100%;
			table-layout: fixed;
			border-collapse: collapse;

			tr {
				th {
					text-transform: uppercase;
					text-align: center;
					color: #C0C0C7;

					&.rdtPrev, &.rdtNext {
						font-size: 25px;
						cursor: pointer; }

					&.rdtSwitch {
						cursor: pointer; } }

				td.calendar-tile {
					height: 37px;
					text-align: center;
					cursor: pointer;
					border: none;
					font-size: 13px;
					line-height: 1;
					border-radius: 100%;
					padding: 11px;

					&.today {
						background-color: $border-light-color; }

					&:hover {
						background-color: $table-hover-background; }

					&.through {
						border-radius: 0;
						background-color: rgba(201, 228, 11, 0.29); }

					&.selected {
						background-color: $apple-green; }

					&.invalid {
						color: $light-gray; }

					&.marginFrom {
						background-color: rgba(201, 228, 11, 0.29);
						border-radius: 100% 0 0 100%; }

					&.marginTo {
						background-color: rgba(201, 228, 11, 0.29);
						border-radius: 0 100% 100% 0; } } }

			tfoot td {
				text-align: center;
				font-size: 17px;
				position: relative;

				&::before {
					position: absolute;
					content: ' ';
					filter: grayscale(100%) brightness(0%);
					background: url("../images/ic-waiting.svg") center no-repeat;
					background-size: 18px 18px;
					width: 18px;
					height: 18px;
					left: 50%;
					top: 50%;
					transform: translateX(-45px) translateY(-55%); } } }

		.rdtTime {
			.rdtCounters {
				margin: auto;

				.rdtCounter, .rdtCounterSeparator {
					display: inline-block;
					vertical-align: middle;
					text-align: center;
					width: 33%;

					.rdtBtn {
						position: relative;
						color: transparent;

						&:first-of-type::before {
							position: absolute;
							content: ' ';
							filter: grayscale(100%) brightness(0%);
							background: url("../images/ic-chevron-up.svg") center no-repeat;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0; }

						&:last-of-type::before {
							position: absolute;
							content: ' ';
							filter: grayscale(100%) brightness(0%);
							background: url("../images/ic-chevron-down.svg") center no-repeat;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0; } } } } } } }


.dropzone-wrapper {
	.file-list {
		width: 210px;

		.file-item {
			display: block;
			padding: 0 0 0 20px;
			margin-top: 5px;
			border: 1px solid $border-color;
			border-radius: $border-radius-input;
			cursor: pointer;

			.button-wrapper, .name-wrapper {
				display: inline-block;
				vertical-align: middle; }

			.name-wrapper {
				width: calc(100% - 40px);
				padding: 5px 0;

				span {
					word-break: break-word;
					line-break: normal; } }

			.button-wrapper {
				width: 40px; }

			&.error {
				border-color: $error; } } }

	.dropzone {
		position: relative;
		height: 210px;
		width: 210px;
		border: 1px dashed $border-color;
		cursor: pointer;

		&.file {
			padding: 10px 40px;
			font-size: 14px;
			font-weight: 400;
			height: auto;
			border-radius: $border-radius-input; }

		&.dragging {
			border: 2px dashed $apple-green; }

		.loaded {
			background: no-repeat center;
			background-size: contain;
			position: absolute;
			width: 100%;
			height: 100%;

			button {
				border: none;
				color: white;
				box-shadow: 0 3px 4px 0 rgba(165, 165, 165, 0.31);
				position: absolute;
				display: none;
				font-size: 10px;
				bottom: 5px;
				left: 50%;
				transform: translateX(-50%);
				background-color: $red-color; }

			&:hover button {
				display: inline; } }

		.empty, .loading {
			background-color: #F5F5F5;
			position: absolute;
			font-size: 13px;
			text-align: center;
			width: 100%;
			height: 100%;
			padding: 45px 32px;
			justify-content: center;
			align-items: center;
			color: #6A6A6A;

			div {
				text-align: center; }

			img, svg {
				width: 68px;
				margin-bottom: 20px;
				align-content: center; } } } }


.info-wrapper {
	position: relative;
	text-align: left;
	margin: auto;
	padding-bottom: 25px;

	label {
		display: block;
		position: relative;
		font-size: 12px;
		margin-bottom: 10px;
		font-weight: 600;
		color: #3f4047;
		transition: all 200ms ease-in-out;

		a {
			text-decoration: underline;
			color: $apple-green; } } }


.input-wrapper {
	position: relative;
	text-align: left;
	margin: auto;
	padding-bottom: 25px;
	min-height: 100px;
	width: 100%;

	.remove-date {
		padding: 8px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 5px;
		color: hsl(0, 0%, 80%); }

	&:not(.select-input) input, textarea {
		padding: 6px 10px;
		background: white;
		border: 1px solid #cccccc;
		border-radius: $border-radius-input;
		width: 100%;
		margin: 0 auto;
		font-size: 13px;
		color: black;
		opacity: 0.8;
		transition: all 100ms linear;

		&.search {
			//background: #EFEEF8
			border: solid 1px #dddddd;
			border-radius: $border-radius-button;
			padding: 10px 20px 10px 65px;
			background: #efeef8 url("../images/ic-search.svg") no-repeat 25px 8px;
			background-size: 20px;
			width: 100%; }

		&:focus {
			box-shadow: 0 2px 13px 0 rgba(178, 178, 178, 0.13);
			border-color: $apple-green; } }

	&.select-input .react-select {
		font-size: 13px;
		color: black;
		border-radius: $border-radius-input;
		border: 1px solid #cccccc;

		input {
			padding: 5px 10px; }

		.react-select-option {
			div {
				display: inline-block;
				vertical-align: middle; } } }

	&.select-input.error .react-select {
		border-color: $error; }

	label {
		display: block;
		position: relative;
		line-height: 24px;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 6px;
		color: #27272A;
		transition: all 200ms ease-in-out; }

	.close-error {
		position: relative;

		.tooltip {
			display: block;
			white-space: normal;
			visibility: visible;
			box-shadow: none;
			background: none;
			top: 0;
			left: 0;
			font-size: 9px;
			padding: 2px 2px;
			color: $error;
			position: relative;
			z-index: 10;
			opacity: 1; } }

	&.error {
		input, textarea {
			box-shadow: 0 2px 13px 0 rgba(233, 187, 187, 0.5);
			border-color: $error; } }

	.input-suggestions {
		display: none;
		position: absolute;
		width: 100%;
		background: white;
		z-index: $z-index-dropdown;
		max-height: 200px;
		overflow: hidden;
		overflow-y: scroll;
		border: 1px solid $border-color;
		top: 75px;
		border-bottom-left-radius: $border-radius-input;
		border-bottom-right-radius: $border-radius-input;

		&.active {
			display: block; }

		&.no-suggestions {
			overflow: hidden;
			max-height: 50px; }

		.suggestion {
			height: 50px;
			line-height: 30px;
			padding: 10px;
			cursor: pointer;

			&:hover {
				background: $apple-green;
				color: white; }

			&.label {
				&:hover {
					background: white;
					color: black; } } } } }

.label {
	display: block;
	position: relative;
	font-size: 12px;
	margin-bottom: 10px;
	font-weight: 600;
	color: #3f4047;
	transition: all 200ms ease-in-out; }

.error-message {
	color: $error;
	font-weight: normal;
	text-align: center;
	font-size: 16px; }

.checkbox-wrapper {
	padding-bottom: 25px;

	label {
		display: block;
		margin-bottom: 12px;
		position: relative;
		font-size: 12px;
		font-weight: 600;
		padding: 2px;
		color: #3f4047;
		transition: all 200ms ease-in-out; }

	.checkbox {
		height: 20px;
		position: relative;
		font-size: 14px;
		line-height: 1.5;
		cursor: pointer;
		color: black;
		transition: color 200ms ease-in-out;
		padding-left: 30px;

		&:before {
			position: absolute;
			content: ' ';
			left: 0;
			top: 0;
			width: 20px;
			height: 20px;
			background: url(../images/ic-uncheck.svg) no-repeat center center;
			background-size: 16px;
			transition: opacity 200ms ease-in-out; }

		&.active:before {
			background: url(../images/ic-check.svg) no-repeat center center;
			background-size: 16px; } } }

th .checkbox-wrapper, td .checkbox-wrapper {
	padding-bottom: 0; }

// Button

.button {
	position: relative;
	display: inline-block;
	background: $apple-green;
	border-radius: $border-radius-button;
	color: white;
	padding: 10px 40px;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	cursor: pointer;
	text-align: center;
	transition: all 100ms linear;
	box-shadow: 0 3px 4px 0 rgba(175, 202, 11, 0.31);
	white-space: nowrap;
	outline: 0;

	&.red-bg {
		box-shadow: 0 3px 4px 0 rgba(202, 12, 16, 0.31); }

	&.outline {
		background: transparent;
		border: 1px solid $apple-green;
		color: $apple-green;
		box-shadow: none; }

	&.remove {
		background: transparent;
		border: none;
		color: $light-gray;
		box-shadow: none;
		padding: 10px 10px; }

	&.icon {
		padding: 5px 12px;
		margin: 0 10px;
		font-size: 20px; }

	span {
		padding-left: 10px; } }

.button:not(.outline):hover {
	background: $light-green; }

// Tables

.table-wrapper {
	&.sticky {
		overflow: unset;
		table {
			table-layout: fixed; }
		.headers {
			background: white;
			position: sticky;
			top: -15px;
			z-index: 20; }
		.scroller {
			overflow-y: hidden;
			overflow-x: scroll;
			&.no-scrollbar::-webkit-scrollbar {
				display: none; } } }
	&.scrollable {
		height: calc(100vh - 422px);
		min-height: 600px;
		.table-loading {
			height: calc(100% - 40px);
			top: 40px; }
		&.with-data {
			height: unset; } }
	table:not(.calendar) {
		width: 100%;
		border-collapse: separate;
		tr:first-of-type {
			.action {
				z-index: 10 !important;
				ul {
					top: 0; } } }

		tr.unsuitable {
			td {
				background-color: rgba(198, 196, 33, 0.36); }

			td:first-of-type:before {
				position: absolute;
				content: " ";
				top: 0;
				left: 0;
				width: 20px;
				height: 100%;
				background: url("../images/ic-invalid.svg") no-repeat center;
				background-size: 20px 45px;
				z-index: 2; }

			td:first-of-type:after {
				position: absolute;
				content: " ";
				top: 0;
				left: 0;
				width: 20px;
				height: 100%;
				background-color: rgba(185, 183, 33, 0.23);
				background-size: 20px 45px;
				z-index: 1; } }

		tr.printed {
			td {
				background-color: rgba(175, 202, 11, 0.1); } }
		tr.created {
			td {
				background-color: rgba(202, 94, 11, 0.1); } }

		tr.invalid {
			td {
				background-color: rgba(245, 166, 35, 0.14); }

			td:first-of-type:before {
				position: absolute;
				content: " ";
				top: 0;
				left: 0;
				width: 20px;
				height: 100%;
				background: url("../images/ic-invalid.svg") no-repeat center;
				background-size: 20px 45px;
				z-index: 2; }

			td:first-of-type:after {
				position: absolute;
				content: " ";
				top: 0;
				left: 0;
				width: 20px;
				height: 100%;
				background-color: rgba(245, 166, 35, 0.28);
				background-size: 20px 45px;
				z-index: 1; } }

		td.action, th.columns {
			//width: auto !important
			min-width: 40px !important;
			padding-right: 15px !important;
			text-align: right !important;
			.actions-wrapper {
				display: inline-block; } }
		td, th {
			position: relative;
			text-align: left;
			border-bottom: 1px solid $divider-color;
			border-bottom: 1px solid $divider-color;
			padding: 15px 10px;
			word-wrap: break-word;

			&:first-child {
				padding-left: 40px; }

			&:last-child {
				padding-right: 40px; }

			.react-select {
				width: 240px; }

			&.regular {
				width: 200px; }
			&.wide {
				width: 300px; }
			&.small {
				width: 50px; }
			&.columns, &.action {
				width: 91px; } }

		th {
			font-size: 11px;
			line-height: 17px;
			font-weight: normal;
			color: $dark-gray;

			&.sortable {
				cursor: pointer;
				white-space: nowrap;

				span {
					margin-right: 5px;
					display: inline-block;
					vertical-align: middle; }

				.sort-icon {
					display: inline-block;
					vertical-align: middle;
					width: 10px;
					height: 11px;
					background: url(../images/ic-sort-none.svg) no-repeat center center;

					&.asc {
						background: url(../images/ic-sort-asc.svg) no-repeat center center; }

					&.desc {
						background: url(../images/ic-sort-desc.svg) no-repeat center center; } } }
			&.columns .filter-icon {
				width: 40px;
				height: 16px; }

			.filter-icon {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				width: 11px;
				height: 13px;
				& + span {
					margin-left: 4px; }
				.icon {
					height: 100%;
					background: url(../images/ic-filter.svg) no-repeat center;
					background-size: contain;
					filter: grayscale(100%) brightness(110%);
					&.active {
						filter: none; } } } }

		tbody {
			tr {
				&:hover {
					box-shadow: 0 2px 35px -11px #cecece;

					td {
						border-top: 1px solid $apple-green;
						border-bottom: 1px solid $apple-green;

						&:first-child {
							border-left: 1px solid $apple-green; }

						&:last-child {
							border-right: 1px solid $apple-green; } } }

				td {
					border-top: 1px solid transparent;

					&:first-child {
						border-left: 1px solid transparent; }

					&:last-child {
						border-right: 1px solid transparent; } } } } } }

.table-filter-wrapper {
	z-index: $z-index-dropdown;
	border-radius: 10px;
	box-shadow: 0 4px 35px -5px #a5a5a5; }

.table-filter {
	min-width: 250px;
	max-height: 500px;
	background: white;
	box-shadow: 0 4px 35px -5px #a5a5a5;
	padding: 30px;
	border-radius: 10px;
	text-align: left;
	&.left {
		right: 0;
		left: unset; }
	&.values {
		min-width: 300px;
		display: flex;
		flex-direction: column;
		.values-wrapper {
			overflow: hidden;
			overflow-y: auto;
			.checkbox-wrapper {
				display: flex;
				justify-content: space-between;
				padding-bottom: 0;
				label {
					font-weight: 500;
					margin-right: 10px; } } }
		&.visible {
			display: flex; } }
	&.visible {
		display: block; }
	.title {
		font-size: 13px;
		font-weight: bold;
		margin-bottom: 5px; }
	.reset {
		text-align: right;
		font-size: 12px;
		font-weight: bold;
		color: $light-gray;
		opacity: 0.5;
		cursor: pointer;
		text-transform: uppercase; }
	&.full-text {
		width: 320px;
		height: 180px; }
	&.date-range {
		width: 340px;
		.date-picker {
			border: none;
			border-radius: 0;
			width: 280px;
			.date-picker-calendar {
				padding: 0;
				width: 100%;
				table {
					width: 100% !important;
					tr:hover .calendar-tile {
						border: none !important; }
					tr th {
						text-transform: uppercase;
						text-align: center;
						color: #C0C0C7;
						padding-left: unset;
						padding-right: unset;

						&.rdtPrev, &.rdtNext {
							font-size: 25px;
							cursor: pointer; }

						&.rdtSwitch {
							cursor: pointer; } }
					td .rdtCounter {
						width: 25px;
						.rdtBtn {
							margin: 0; } } } } } } }
.table-wrapper {
	position: relative;
	overflow-y: hidden;
	overflow-x: scroll;
	min-height: 200px;
	&.scrollable.sticky .content .scroller {
		min-height: 200px; } }

.table-loading {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	text-align: center;
	font-size: 16px;
	color: $light-gray;
	position: absolute;
	background-color: white;
	border-radius: $border-radius-box;

	div {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%); }

	span {
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px; }

	svg {
		display: inline-block;
		vertical-align: middle; } }

table td {
	cursor: pointer;

	&.active-popup {
		z-index: 100 !important; }

	&.icon {
		font-size: 14px; }

	&.action {
		position: relative;
		width: 60px;

		.actions {
			top: 50%;
			transform: translateY(-50%); } } }

.actions-wrapper {
	z-index: 1111;
	height: 40px;
	width: 40px;
	padding: 9px;

	.actions {
		cursor: pointer;
		display: block;
		position: relative;
		overflow: visible;
		height: 100%;
		width: 100%;
		text-align: center;
		color: $light-gray;
		font-size: 16px;

		ul {
			font-size: 12px;
			display: none;
			position: absolute;
			bottom: -50%;
			right: 10px;
			box-shadow: 0 2px 14px 2px rgba(191, 191, 191, 0.23);
			border-radius: $border-radius-box;

			li {
				background: white;
				padding: 8px 20px;
				color: $light-gray;
				border-bottom: 1px solid $divider-color;
				cursor: pointer;

				span {
					color: black;
					padding-left: 15px; }

				&:not(.no-action):hover {
					color: white;
					background: $apple-green;

					span {
						color: white; } }

				&:first-child {
					border-top-left-radius: $border-radius-box;
					border-top-right-radius: $border-radius-box; }

				&:last-child {
					border-bottom-left-radius: $border-radius-box;
					border-bottom-right-radius: $border-radius-box; } } }

		&.active ul {
			display: block;
			white-space: nowrap; } } }

.tooltip-list {
	li {
		font-size: 14px;
		padding: 3px 0; } }

.container {
	padding: 0;
	height: calc(100% + 65px); }

// Footer

footer {
	border-top: 2px solid $apple-green;
	bottom: 0;
	background: white;
	height: 75px;
	@media (max-width: 991px) {
		width: 100%; }

	.footer-content {
		padding: 15px;
		display: flex;
		justify-content: space-between;
		vertical-align: middle;

		.footer-item {
			font-size: 12px;
			font-weight: bold;
			line-height: 40px;

			.jrk-logo {
				height: 40px;
				margin-left: 15px; }

			.elwis-logo {
				width: 100px;
				margin-left: 15px; }

			a {
				color: $apple-green; } } } }

.box {
	margin-bottom: 20px;

	.box-title {
		font-size: 10px;
		padding: 20px 40px;
		color: $dark-gray;
		text-transform: uppercase;
		letter-spacing: 1px; }

	.box-content {
		box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.25);
		background: white;

		&.with-padding {
			padding: 0 40px;
			@include extra-small {
				padding: 0 20px; } }

		.box-head {
			padding: 20px 40px 0 40px;
			border-top-left-radius: $border-radius-box;
			border-top-right-radius: $border-radius-box;

			.input-wrapper {
				min-height: 65px; }

			button {
				@include small {
					padding-left: 10px;
					padding-right: 10px;
					border: transparent; }

				&.import-button {
					@include small {
						padding: 3px 7px 4px 7px;
						border: 1px solid $apple-green;
						font-size: 14px; } } }

			@include small {
				padding: 20px 30px 0 30px; } }

		.box-body {
			border-bottom-left-radius: $border-radius-box;
			border-bottom-right-radius: $border-radius-box;
			padding: 0 0 40px 0;
			@include medium {
				overflow: auto; }

			&.with-divider {
				border-top: 1px solid $divider-color; } } }

	.box-footer {
		button {
			margin: 20px 10px 0 10px; } }

	@include extra-small {
		margin-left: -15px;
		width: calc(100% + 30px); } }

.box-simple {
	display: flex;
	margin-bottom: 20px;
	text-align: center;
	padding: 20px 30px;
	background: white;
	width: 100%;
	border-radius: $border-radius-box;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	min-height: 120px;
	box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.25);

	h4 {
		width: 100%;
		font-size: 16px; }

	&.large {
		min-height: 480px; } }

// Header

header {
	z-index: 100;
	position: relative !important;
	top: 0;
	width: 100%;
	height: 65px;
	box-shadow: 0 2px 7px 0 rgba(194, 194, 194, 0.5);
	background: white;
	@include medium {
		background: #262845; } }


.logo {
	padding: 15px;
	box-shadow: none; }

#body > header > div.logo > img {
	display: block;
	margin: auto;
	width: 135px;
	@include medium {
		position: absolute;
		width: 100px;
		left: 15px; } }

#body > div.container > div.main > div.row > div:nth-child(1) {
	padding-left: 30px; }

header {
	.header-content {
		padding: 0 0;
		background: white;

		.select {
			padding: 15px;
			@include medium {
				margin: 0 0 20px 20px;
				height: 50px;
				border: 1px solid $blue-gray;
				border-radius: 4px;
				padding-right: 0;
				width: 100%;
				white-space: nowrap;
				font-size: 12px; }

			input {
				&.option {
					@include medium {
						padding: 14px 20px 14px 20px;
						min-width: unset;
						width: 100%; }
					@include extra-small {
						padding-left: 0; } } }

			.option-list {
				.option-list-item {
					.option-list-item-title {
						@include extra-small {
							padding-left: 15px; } } } }

			.icon {
				@include extra-small {
					display: none; } } }

		.mobile-menu {
			@include medium {
				display: flex; } }

		&.active {
			@include medium {
				display: block;
				width: 50%;
				height: calc(100vh - 65px);
				overflow: auto;
				position: absolute;
				right: 0;
				top: 65px; }
			@include small {
				width: 100%; } }

		@include medium {
			display: none;
			float: left;
			width: 100%;
			box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.39); } }

	@include medium {
		position: absolute;
		z-index: $z-index-header;
		top: 0; }

	aside {
		display: none;
		@include medium {
			overflow: auto;
			display: block;
			width: 100%;
			float: left; }
		@include large {
			width: 100%; } } }

header {
	.select {
		height: 37px;
		width: 300px;
		border-left: 1px solid $divider-color; } }

// Aside menu

.col-2.menu {
	padding: 0 !important; }

aside {
	background-color: #262845;
	padding-bottom: 20px;
	border: none;

	.menu-box {
		padding: 5px 0 0 10px;

		.box-head {
			font-size: 12px;
			padding: 20px 0 10px 0;
			color: white;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: bold;
			@include medium {
				padding: 20px 20px 10px 20px; }
			@include large {
				width: 245px; }
			@include extra-large {
				width: 245px; } }

		.box-body {
			ul {
				background: #262845;
				border-radius: $border-radius-box;

				li {
					font-size: 12px;
					letter-spacing: 1px;
					height: 35px;

					a {
						padding: 5px 20px;
						color: white;
						text-decoration: none;
						display: block;
						width: 100%;
						height: 100%;

						.icon {
							display: inline-block;
							vertical-align: middle;
							height: 100%;
							width: 20px;
							filter: grayscale(100%);
							background: url('../images/shape.svg') no-repeat center center; }

						span {
							display: inline-block;
							margin-left: 15px; } }

					&:hover, &.active {
						background: $apple-green;
						color: white;
						border-top-left-radius: $border-radius-box;
						border-bottom-left-radius: $border-radius-box;

						.icon {
							filter: brightness(0%) invert(1); }

						a {
							color: white;
							font-weight: bold;
							padding-left: 15px; } } }

				@include medium {
					box-shadow: none;
					border-radius: 0; } } }

		&:first-child {
			.box-head {
				padding-top: 0; } } }

	@include extra-small {
		background: #262845; } }

// Breadcrumbs

.breadcrumbs {
	width: 100%;
	padding: 20px 15px;
	font-size: 12px;
	@include medium {
		font-size: 10px; }

	ul {
		li {
			display: inline;

			a {
				color: $apple-green; }

			&:not(:first-child):before {
				content: '>';
				margin: 0 10px; } } }

	.switch-wrapper {
		display: none; }

	@include medium {
		margin-top: 40px; }
	@include extra-small {
		margin-left: -15px;
		width: calc(100% + 30px);
		border-radius: 0;
		margin-bottom: 10px;
		margin-top: 0; } }


// Pagination

.pagination {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin-top: 30px;

	.pagination-content {
		cursor: pointer;
		display: inline-block;
		line-height: 46px;

		.page {
			padding: 14px;
			float: left;
			height: 46px;
			width: 46px;
			font-size: 15px;
			color: $dark-gray;
			text-align: center;
			@include large {
				width: 30px; }

			&:hover, &.active {
				border-radius: 5px;
				border-bottom: 5px solid #AFCA0B; } }

		.previous, .next, .last, .first {
			text-align: center;
			font-size: 16px;
			color: $apple-green; }
		span {
			text-align: center;
			font-size: 16px;
			color: $apple-green;
			margin: 0 -14px; } }
	.total {
		margin-top: 10px; } }

.limit-wrapper {
	float: right;
	line-height: 46px;
	display: flex;
	margin: 0 10px 0 0;
	color: $apple-green;
	.title {
		margin: 9px; }
	.limit {
		cursor: pointer;
		margin: 10px;
		&.active {
			font-weight: bolder;
			text-decoration: underline; } } }

// Select
.select {
	padding: 0 20px;
	display: table;
	height: 100%;
	position: relative;
	overflow: visible;
	z-index: $z-index-dropdown;
	float: left;
	cursor: pointer;
	min-width: 200px;

	.icon {
		display: table-cell;
		width: 32px;
		background: url("../images/img-erb-placeholder.svg") no-repeat center center;
		background-size: 32px auto; }

	.option {
		padding: 0 20px;
		vertical-align: middle;
		width: calc(100% - 48px - 30px);
		display: table-cell;
		background: white;
		font-size: 14px;
		font-weight: bold; }

	input.option {
		border: none;
		padding: 8px 20px;
		min-width: 200px;

		&::placeholder {
			font-size: 14px;
			font-weight: bold;
			color: black;
			@include small {
				font-size: 12px; } } }

	.select-arrow {
		width: 30px;
		height: 100%;
		vertical-align: middle;
		display: table-cell;
		background: white;
		margin: auto;
		border-top-right-radius: $border-radius-box;
		border-bottom-right-radius: $border-radius-box;
		background: url("../images/ic-chevron-down.svg") no-repeat center center;
		background-size: 13px auto; }

	&.selecting .icon {
		border-bottom-left-radius: 0; }

	&.selecting .select-arrow {
		border-bottom-right-radius: 0; }

	&.selecting .option-list {
		display: block; }

	.option-list {
		display: none;
		z-index: $z-index-dropdown;
		position: absolute;
		top: 64px;
		left: 0;
		width: 100%;
		background-color: white;
		padding: 10px;
		box-shadow: 5px 15px 25px 0 rgba(179, 179, 179, 0.25);
		border: 1px solid $lavender;
		border-top: none;
		border-bottom-left-radius: $border-radius-input;
		border-bottom-right-radius: $border-radius-input;

		.list-wrapper {
			max-height: 275px;
			overflow: auto;
			overflow-x: hidden;
			border-bottom-left-radius: $border-radius-input;
			border-bottom-right-radius: $border-radius-input;

			.option-list-item {
				cursor: pointer;
				width: 100%;
				background: white;
				padding: 6px 15px;

				.loading .icon {
					background: none; }

				&:last-child {
					border-bottom-left-radius: $border-radius-input;
					border-bottom-right-radius: $border-radius-input; }

				.option-list-item-title {
					height: 35px;
					display: table-cell;
					vertical-align: middle;
					font-size: 12px;
					line-height: 37px;
					padding: 0 0 0 30px;

					.option-text {
						display: inline-block;
						vertical-align: middle; }

					.selected-icon {
						display: inline-block;
						vertical-align: middle;
						width: 12px;
						height: 12px;
						background: url("../images/ic-chevron-right.svg") no-repeat left center;
						background-size: 12px;
						margin-left: -5px;
						margin-right: 10px; }

					@include small {
						font-size: 12px; } }

				&.selected {
					font-weight: bold;
					background: $selected-color;
					color: white; }

				&:hover {
					background: $apple-green;
					color: white; }

				.icon {
					display: table-cell;
					height: 24px;
					width: 21px;
					background: url("../images/img-erb-placeholder.svg") no-repeat center center;
					background-size: 24px auto;
					margin-right: 30px;
					@include small {
						display: none; } } } } } }

// List
ul.list {
	li {
		display: table;
		width: 100%;
		height: 55px;
		border-bottom: 1px solid $divider-color;

		.list-item {
			padding-left: 20px;
			text-align: left;
			display: table-cell;
			vertical-align: middle;

			small {
				padding-left: 10px;
				color: $light-gray; } }

		.value {
			vertical-align: middle;
			display: table-cell;
			font-size: 16px;
			font-weight: 600;
			width: 65px;
			padding-left: 5px;
			text-align: right; }

		&:last-child {
			border-bottom: none; } } }

// Dropdown

.dropdown {
	position: absolute;
	top: 70px;
	border-radius: $border-radius-box;
	box-shadow: 0 0 15px 1px rgba(81, 77, 92, 0.2);
	text-align: left;
	transition: all 150ms ease-in-out;
	visibility: hidden;
	opacity: 0;
	z-index: $z-index-dropdown;

	h3 {
		font-weight: bold;
		margin: 0;
		color: #3f4047;
		font-size: 16px; }

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			background: white;
			color: $light-gray;
			border-bottom: 1px solid $divider-color;
			cursor: pointer;

			div {
				display: block;
				padding: 12px 25px;
				color: black;

				&:hover {
					color: white; } }

			span {
				color: black;
				padding-left: 15px; }

			&:first-child {
				border-top-left-radius: $border-radius-box;
				border-top-right-radius: $border-radius-box;

				&:hover {
					background: $apple-green;
					border-top-left-radius: $border-radius-box;
					border-top-right-radius: $border-radius-box; } }

			&:last-child {
				border-bottom-left-radius: $border-radius-box;
				border-bottom-right-radius: $border-radius-box;

				&:hover {
					background: $apple-green;
					border-bottom-left-radius: $border-radius-box;
					border-bottom-right-radius: $border-radius-box; } } } }

	.dropdown-header {
		padding: 0 30px 20px 30px;
		border-bottom: 1px solid #F4F5F8; }

	&.shown {
		visibility: visible;
		opacity: 1;
		transform: translateY(0); } }

// Profile
.profile {
	cursor: pointer;
	height: 100%;
	display: flex;
	float: right;
	padding: 15px 20px 0 10px;
	.profile-info {
		display: block;
		width: 19px;
		background: url("../images/ic-info.svg") center no-repeat; }
	.profile-name {
		display: inline;
		min-width: 160px;
		padding: 5px 20px;

		h4 {
			margin: 0;
			font-weight: bold;
			font-size: 12px; }

		small {
			color: $light-gray;
			font-size: 11px; } }

	.profile-arrow {
		padding: 10px 0;
		width: 10px;
		display: inline;
		vertical-align: middle; }

	@include medium {
		padding-top: 4px; }
	@include extra-small {
		display: none; } }

.profile-mobile {
	display: none;
	float: right;

	.profile-avatar {
		width: 52px;
		height: 52px;
		display: none;
		background: url("../images/ic-avatar.svg") no-repeat center center;
		@include medium {
			display: flex;
			height: 52px; } }

	@include extra-small {
		display: flex;
		padding: 0 10px 0 20px; } }

// Badges

.badge {
	display: inline-block;
	padding: 4px 6px;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 10rem; }

// Notifications

.notifications {
	width: 45px;
	float: right;
	display: inline-flex;
	text-align: center;
	cursor: pointer;
	height: 100%;
	padding: 5px 0;

	.notifications-icon {
		width: 26px;
		height: 26px;
		background: url("../images/ic-notification.svg") no-repeat scroll center center;
		background-size: 22px;

		.badge {
			font-size: 11px;
			color: white;
			position: relative;
			left: 20px;
			top: -5px; } } }

// Brand logo

.logo {
	float: left;
	height: 100%;
	background: #262845;
	width: 250px;
	text-align: right;
	@include extra-small {
		z-index: 2;
		width: 100%;
		background-position: left 10px center; }
	@include large {
		width: 250px; } }

.toggle-menu {
	cursor: pointer;
	height: 65px;
	width: 30px;
	display: none;
	background: url("../images/ic-hamburger.svg") no-repeat center center;
	margin-right: 20px;

	&.active {
		background: url("../images/ic-close.svg") no-repeat center center; }

	@include medium {
		display: block; } }

/* Toast message */

.toast-message {
	position: fixed;
	top: 30px;
	right: 30px;
	width: 400px;
	border: 1px solid $border-color;
	border-top: 5px solid white;
	background: white;
	z-index: $z-index-toast;
	padding: 20px;
	border-radius: 5px;
	transform: translateY(-100%);
	box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.39);
	transition: transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1); }

.toast-message.shown {
	transform: translateY(0);
	box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15); }

.toast-message .message-text {
	font-size: 14px;
	font-weight: 600;
	max-width: 80%;
	margin-bottom: 10px; }

.toast-message[data-color=red] {
	border-top-color: $error;
	color: $error; }

.toast-message[data-color=blue] {
	border-top-color: blue;
	color: blue; }

.toast-message[data-color=green] {
	border-top-color: $apple-green;
	color: $apple-green; }

.toast-message[data-color=orange] {
	border-top-color: orange;
	color: orange; }

.toast-message[data-color=yellow] {
	border-top-color: yellow;
	color: yellow; }

/* Modal */

.modal {
	position: fixed;
	padding: 0;
	transition: all 200ms linear;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	z-index: $z-index-modal; }

.modal.shown {
	display: block;
	opacity: 1;
	visibility: visible; }

.modal:after {
	display: inline-block;
	height: 100%;
	margin-left: -.05em;
	content: ' ';
	vertical-align: middle; }

.modal:before {
	position: fixed;
	content: ' ';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
	transition: all 200ms linear;
	z-index: $z-index-modal-background; }

.modal-wrapper {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	z-index: $z-index-modal-background;
	opacity: 0;
	visibility: hidden;
	margin: 20px;
	background: white;
	border-radius: 4px;
	width: calc(100% - 40px);
	max-width: 1400px;
	text-align: left;
	transform: scale(0.7);
	transition: opacity 200ms linear, visibility 200ms linear, transform 200ms linear;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.25); }

.modal.shown .modal-wrapper {
	opacity: 1;
	visibility: visible;
	transform: scale(1); }

.modal[data-size="s"] .modal-wrapper {
	max-width: 550px; }

.modal[data-size="m"] .modal-wrapper {
	max-width: 720px; }

.modal[data-size="l"] .modal-wrapper {
	max-width: 900px; }

.modal-photo {
	height: 200px;
	background: white no-repeat center center;
	background-size: cover;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px; }

.modal-header {
	padding: 25px 25px 25px 30px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background: $apple-green;
	color: white;
	font-size: 18px;
	font-weight: 400;
	border: none; }

.modal-header h3 {
	font-size: 16px;
	font-weight: bold;
	color: $light-gray;
	margin: 0; }

.modal-content {
	padding: 25px 25px 25px 30px;
	white-space: normal;
	font-size: 15px;
	border: none; }

.modal-footer {
	border-top: 1px solid $apple-green;
	padding: 0 25px 25px 30px;
	text-align: right; }

.modal-footer .button {
	margin-left: 20px; }

.react-numeric-input {
	width: 100%; }

.not-found-wrapper {
	text-align: center;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.not-found-image {
		width: 420px;
		height: 420px;
		margin: auto;
		background-position: center;
		background-size: contain; }

	.not-found .header {
		font-size: 43px;
		font-weight: bold;
		color: $apple-green; }

	.not-found .content {
		font-size: 13px;
		font-weight: normal;
		color: $light-gray;
		text-align: center;

		& a {
			color: $light-gray;
			text-decoration: none; }

		& a:hover {
			color: $apple-green;
			text-decoration: underline; } } }

.button {
	&.disabled {
		color: #d4d4d4;
		background: #f6f6f6;
		border-color: #d4d4d4;
		cursor: not-allowed;
		@include extra-small {
			background: transparent; } } }

.page-loading {
	top: 0;
	left: 0;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 1);
	z-index: $z-index-loading;
	opacity: 0;
	visibility: hidden;
	transition: all 200ms ease-in-out;

	&.shown {
		opacity: 1;
		visibility: visible; } }


.loader, .loader:before, .loader:after {
	background: $apple-green;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: loading 1s infinite ease-in-out;
	width: 0.5em;
	height: 2em;
	border-radius: 3px; }

.loader {
	color: $apple-green;
	text-indent: -9999em;
	margin: 88px auto;
	position: relative;
	font-size: 11px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;

	&:before, &:after {
		position: absolute;
		top: 0;
		content: ''; }

	&:before {
		left: -1.25em;
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s; }

	&:after {
		left: 1.25em; } }

/* Animations */

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content; }
	@-moz-keyframes #{$name} {
		@content; }
	@-ms-keyframes #{$name} {
		@content; }
	@keyframes #{$name} {
		@content; } }

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg); } }

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }

@-webkit-keyframes loading {
	0%, 80%, 100% {
		box-shadow: 0 0;
		height: 2em; }

	40% {
		box-shadow: 0 -2em;
		height: 2.5em; } }

@keyframes loading {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 2em; }
	40% {
		box-shadow: 0 -2em;
		height: 2.5em; } }

// Calendar

table.calendar {
	margin-top: 50px;
	width: calc(100% - 1px);
	table-layout: fixed;
	border-collapse: collapse;
	margin-bottom: 10px;

	tr {
		th {
			font-size: 13px;
			font-weight: 400;
			text-transform: uppercase;
			text-align: center;
			padding-bottom: 10px;
			color: #C0C0C7; }

		td {
			border: 1px solid $border-light-color;
			transition: all 200ms ease-in-out;
			cursor: pointer;

			&:first-of-type {
				border-left: 1px solid $border-light-color; }

			&:last-of-type {
				border-right: 1px solid $border-light-color; }

			&:hover {
				background-color: $table-hover-background; }

			&.calendar-day {
				padding: 15px;
				height: 88px;
				position: relative;
				color: $calendar-day-color;
				opacity: 1;

				&.today {
					background-color: $table-hover-background;
					color: $apple-green; }

				&.selected {
					background-color: #89a30b;
					color: white; }

				&.another {
					opacity: 0.5; }

				.calendar-date {
					font-weight: 600;
					line-height: 1;
					margin-bottom: 12px;
					font-size: 28px;

					.day-label {
						display: inline-block;
						font-size: 11px;
						font-weight: bold;
						text-transform: uppercase;
						vertical-align: 12px;
						margin-left: 5px; } }

				.calendar-labels {
					width: 110px;
					height: 24px;

					.calendar-label {
						vertical-align: middle;
						display: inline-block;
						margin-right: 2px;
						width: 12px;
						height: 12px;
						border-radius: 100%;
						padding-top: 3px;
						text-align: center;
						font-size: 9px;
						font-weight: 600;
						background: #DEE1E9; } } } } } }

.circle {
	font-size: 15px;
	border: 1px solid $light-gray;
	color: $light-gray;
	border-radius: 100%;
	height: 37px;
	width: 37px;
	margin: auto;
	padding: 7px;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	display: inline-block; }

.month-name {
	padding: 2px;
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	vertical-align: middle;
	display: inline-block;
	margin: 0 10px;
	min-width: 230px; }

.period-year-picker {
	padding: 20px;
	text-align: center;

	.month-name {
		min-width: unset; } }

// Charts

.chart-title {
	font-size: 16px;
	font-weight: 600;
	color: black;
	margin-bottom: 15px;
	padding: 0 10px;
	text-align: center;
	&.collection-place {
		text-align: left;
		font-size: 12px; } }

.chart-wrapper {
	text-align: center;
	padding: 15px;

	&.scrollable {
		overflow-x: scroll; }

	.chart {
		height: 500px;
		width: 100%;
		min-width: 100%; } }

.chart-legend {
	font-size: 12px;
	padding: 15px 15px;
	min-width: 130px; }

.chart-legend-collection-place {
	flex: 1;
	text-align: left;
	margin-left: 10px;
	div {
		line-height: 25px; } }

// Has to be on the bottom
.show-on-mobile {
	display: none;
	@include medium {
		display: block; } }

// Flex rows

.flex-row {
	display: flex;
	margin: 15px 0;

	&.flex-right {
		justify-content: flex-end; }

	.flex-item {
		margin: 0 5px; } }

@media (max-width: 800px) {
	.flex-row {
		flex-wrap: wrap;

		.flex-item {
			flex: auto;
			margin-bottom: 15px; } } }

.range-picker-options-wrapper {
	padding-bottom: 20px;

	.range-picker-option {
		cursor: pointer;
		color: $light-gray;
		font-weight: bold;
		font-size: 13px;
		text-align: center;
		padding: 10px 15px;
		text-transform: uppercase;

		&:hover {
			color: $apple-green; } } }

.spinner {
	display: flex;
	justify-content: center;
	align-items: center; }

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: $apple-green;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s; }

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s; }

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0); }
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0); } }

.metrics {
	span {
		font-size: 14px; }

	h2 {
		font-size: 20px;
		margin-bottom: 40px; }

	ul {
		width: 100%;

		li {
			.list-item {
				padding: 0 5px; } } }

	.chart-legend-item {
		margin-bottom: 10px; }

	.stats-range-picker {
		.input-wrapper {
			min-height: 60px;

			.range-picker-input {
				font-size: 16px; } } }

	.input-wrapper:not(.select-input) {
		input {
			padding: 0 20px; } }

	.header {
		height: calc(16px * 1.4 * 2);
		width: 100%;
		margin: 10px 0;

		h4 {
			margin: 0; } }

	.dummy {
		width: 100%;
		height: 60px;
		padding-bottom: 25px; }

	.empty {
		height: 75%;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		flex-direction: column; }

	.icon {
		width: 36px;
		height: 36px;
		background: center no-repeat; } }

.metrics-flex {
	flex: 1;
	flex-direction: column;
	justify-content: flex-start; }

.dashboard-calendar {
	@include large {
		width: 100%;
		display: flex;
		justify-content: center; }

	.box {
		.box-content {
			border-radius: 0;
			box-shadow: unset;
			border-right: 1px solid $divider-color;
			@include large {
				border-right: unset; } }

		.box-head {
			padding-bottom: 10px;
			border-bottom: 1px solid $divider-color; }
		width: 400px; }

	table {
		&.calendar {
			margin-top: 30px;

			tr {
				td {
					border: unset;

					&.calendar-day {
						height: unset;
						border-radius: 50%;

						&.selected {
							background-color: $apple-green; } }

					.calendar-labels {
						width: 12px;
						height: 16px;
						overflow: hidden;
						position: absolute;
						top: 0;
						right: 0; }

					.calendar-date {
						font-size: 14px;
						margin-bottom: unset;
						text-align: center;

						.day-label {
							display: none; } } }

				th {
					padding-bottom: 15px; } } } } }

.dashboard-events {
	flex: 1;
	padding: 0 20px;

	.event-wrapper {
		border-radius: 5px;
		border: 1px solid $blue-gray;
		padding: 10px 20px 20px 20px;
		margin: 10px 0;

		h3 {
			font-size: 14px;
			color: black;
			font-weight: bold;
			margin-bottom: 0;
			line-height: 18px; }

		p {
			color: #7C7C7C;
			font-size: 12px;
			margin: 2px 0 3px 0;

			&.date {
				font-size: 10px;
				color: $apple-green; } } } }

.input-switch {
	padding-bottom: 30px;
	width: 100%; }

.radio-wrapper {
	.radio-label {
		display: inline-block;
		vertical-align: middle; }

	.radio-button {
		height: 30px;
		width: 30px;
		display: inline-block;
		vertical-align: middle;
		background: url("../../resources/images/ic-radio-off.svg") center no-repeat;

		&.active {
			background: url("../../resources/images/ic-radio-on.svg") center no-repeat; } } }

.sorting-header {
	text-align: center;
	font-size: 24px;

	.cancel {
		cursor: pointer;
		color: $error; } }

/* Tabs */
.tab-content {
	position: relative;
	z-index: 10; }

.nav-tabs {
	text-align: center;
	background: transparent;
	color: #1C1C1C;
	border: none;
	@include medium {
		height: 40px; }

	.nav-item.nav-link {
		background-color: white;
		padding: 15px 45px;
		border: none;
		@include medium {
			padding: 10px 10px; }

		&:last-child {
			border-top-right-radius: 15px;

			&.active {
				&:after {
					background-color: white;
					content: "";
					width: 15px;
					height: 36px;
					top: 0;
					left: 0;
					position: absolute;
					z-index: -1; } } }

		&:first-child {
			border-top-left-radius: 15px;

			&.active {
				&:after {
					background-color: white;
					content: "";
					width: 15px;
					height: 36px;
					top: 0;
					right: 0;
					position: absolute;
					z-index: -1; } } }

		&:not(:first-child):not(:last-child) {
			&.active {
				&:after {
					background-color: white;
					content: "";
					width: 15px;
					height: 36px;
					top: 0;
					right: 0;
					position: absolute;
					z-index: -1; }

				&:before {
					background-color: white;
					content: "";
					width: 15px;
					height: 36px;
					top: 0;
					left: 0;
					position: absolute;
					z-index: -1; } } }

		& a {
			color: #1C1C1C; } } }

.nav-tabs .nav-link {
	a {
		float: left; }

	@include medium {
		width: 80px;
		font-size: 8px;
		margin: 0; }

	&.active {
		background: $apple-green;
		color: white;
		font-weight: bold;
		display: inline-block;
		position: relative;

		&:hover {
			color: white; }

		&:after {
			background-color: white;
			content: "";
			width: 15px;
			height: 36px;
			top: 0;
			right: 0;
			position: absolute;
			z-index: -1; } }

	&:hover {
		color: $apple-green; } }

.menu {
	background: #262845;
	width: 317px; }

#collection-place-tabs-tab-list-of-containers,
#collection-place-tabs-tab-basic-info,
#collection-place-tabs-tab-customer-list {
	@include large {
		padding-left: 25px;
		min-width: 100px; }
	@include medium {
		padding-left: 5px; } }

/*Layout */
.row-flex {
	display: flex;
	flex-direction: row;
	width: 100%; }

.col-flex {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 3px; }

.col-flex-content {
	display: flex;
	flex-direction: column;
	flex: 5;
	@include medium {
		width: 100%; } }

.menu {
	border: none;
	margin: 0;
	@include medium {
		display: none; } }

.main {
	margin-left: 250px;
	height: calc(100% - 65px);
	min-height: calc(100vh - 65px);
	width: calc(100% - 250px);
	padding: 15px 15px 0 15px;
	overflow-y: auto;
	overflow-x: hidden;
	.row {
		align-content: flex-start;
 }		//min-height: calc(100% - 76px)
	@include large {
		padding: 10px 10px 0 10px;
		margin-left: 250px;
		margin-right: 0;
		footer {
			margin: 0 -10px; } }
	@include medium {
		width: 100%;
		margin-left: 0; }
	footer {
		margin: 0 -15px; } }



.sidebar {
	height: calc(100% - 65px);
	width: 250px;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	z-index: 100;
	left: 0;
	top: 0;
	margin-top: 65px;
	background: #262845;
	@include medium {
		display: none;
		margin-top: 0; } }


/* Scrollbar */

::-webkit-scrollbar {
	width: 0.8em;
	height: 0.8em; }

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

::-webkit-scrollbar-thumb {
	//background-color: #c6c7ce
	background-color: $apple-green; }

// Mass changes pop-up window
.toast {
	max-width: 500px;
	position: fixed;
	bottom: 75px;
	left: 250px; }

.close-button {
	height: 17px;
	position: absolute;
	right: 20px;
	top: 15px;
	filter: grayscale(100%); }

.containers-summary {
	border-radius: 10px;
	border: 1px solid $border-color;
	display: flex;
	justify-content: stretch;
	margin-bottom: 15px;
	margin-left: -10px;
	.waste-type-legend {
		width: 91px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		background-color: #F9F8FC;
		.waste-type-legend-value {
			text-align: center;
			height: 40px;
			line-height: 40px;
			min-width: 60px;
			border-bottom: 1px solid $border-color;
			&.active {
				border: none; } }
		.waste-type-legend-consumption {
			display: flex;
			.waste-type-legend-consumption-values {
				border-left: 1px solid $border-color; }
			.waste-type-legend-consumption-title {
				background-color: #F1EFF9;
				position: relative;
				min-width: 30px;
				border-bottom: 1px solid $border-color;
				div {
					position: absolute;
					top: 50%;
					left: 0;
					transform: rotate(-90deg);
					width: 100%; } } } }
	.containers-data {
		display: flex;
		width: calc(100% - 91px);
		border-left: 1px solid $border-color;
		justify-content: stretch;
		.one-time {
			display: flex;
			flex: 1;
			overflow: hidden;
			overflow-x: auto; }
		.permanent {
			border-left: 1px solid $border-color;
			.waste-type-column {
				width: 125px; }
			.waste-type-name {
				border-top-right-radius: 10px; } }
		.waste-type-column {
			min-width: 90px;
			text-align: center;
			flex: 1;
			.waste-type-name, .waste-type-value {
				flex: 1;
				height: 40px;
				line-height: 40px;
				border-bottom: 1px solid $border-color; }
			.waste-type-value:last-of-type {
				border-bottom: none; }
			.waste-type-name {
				background-color: #F1EFF9;
				white-space: nowrap;
				span {
					max-width: calc(100% - 25px);
					white-space: normal;
					text-overflow: ellipsis;
					overflow: hidden; }
				&:last-of-type {
					border-top-right-radius: 10px; } } } } }

.ant-table-wrapper .ant-table-thead {
	> tr {
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
			height: 70%!important; } } }

.mo-datetime-picker-field {
	input[disabled] {
		opacity: 0.6;
		background: #eaeaea;
		cursor: not-allowed; } }
